import React from 'react'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
import '../css/style.css'
import '../css/reset.css'
import '../css/responsive.css'
import '../css/glightbox.css'
function Login() {
  return (
    <>
        <Header/>

        <section class="my_account login">
      <div class="account_inner">
         <div class="account_left" style={{backgroundImage: `url(${'../../images/carefree.webp'})`}}>
            {/* <!-- <img src="images/carefree.webp" alt="account_bg img" /> --> */}
            <div class="white_logo">
               <img src="images/WhiteLogo.webp" alt="white logo image"/>
            </div>
         </div>
         <div class="account_content">
            <img src="images/form_company_logo.webp" alt="company logo image" />
            <h2 class="box_heading">Login to you account</h2>
            <form class="login_account" method="post" action="#" id="login_form">
               <div class="form_group ">
                   <label for="phone">Phone Number*</label>
                   <div class="phone_box">
                      <select id="country_code" name="country_code">
                          <option value="+965">+965</option>
                          <option value="+91">+91</option>
                      </select>
                      <input type="text" id="phone" name="phone" placeholder="Enter phone number" maxlength="10" minlength="10" required/>
                   </div>
               </div>
               <div class="form_group">
                   <label for="password">Password*</label>
                   <div class="password_box">
                       <input type="password" id="password" name="password" class="password_input" placeholder="Password" required/>
                       <span class="show_password" onclick="togglePassword()">👁️</span>
                   </div>
               </div>
               <div class="form_group remember_me">
                  <div class="remember_box">
                        <input type="checkbox" id="remember_me" name="remember_me"/>
                        <label for="remember_me">Remember me</label>
                  </div>
                   <a href="#/forget-password" class="forgot_password">Forgot password?</a>
               </div>
               <button type="submit" class="login_button" value="Login Now">Login Now</button>
               <div class="create_account">
                   <p><span>Don't have an account? </span><a href="#/registration">Create New account</a></p>
               </div>
            </form>
         </div>
      </div>
   </section>

   <Footer/>
    </>
  )
}

export default Login
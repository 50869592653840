import React from 'react'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'

function Registration() {
  return (
   <>
    <Header/>
    <section class="my_account account_create">
      <div class="account_inner">
         <div class="account_left" style={{backgroundImage: `url(${'../../images/carefree.webp'})`}}>
            <div class="white_logo">
               <img src="images/WhiteLogo.webp" alt="white logo image"/>
            </div>
         </div>
         <div class="account_content">
            <h2 class="box_heading">Create New Account</h2>
            <form class="login_account create_form" method="post" action="#" id="create_form">   
               <div class="avatar_box">
                  <div class="avatar_inner">
                      <img src="images/OBJECTS.webp" alt="Avatar" id="avatarPreview" class="avatar_img"/>
                      <label for="avatarUpload" class="upload_btn">
                          <img src="images/camera-icon.webp" alt="Camera Icon" width="20" height="20"/>
                      </label>
                      <input type="file" id="avatarUpload" name="avatarUpload" accept="image/*" onchange="previewAvatar(event)"/>
                  </div>
               </div>
               <div class="form_group">
                   <label for="fullName">Full Name*</label>
                   <input type="text" id="fullName" name="fullName" placeholder="Enter Full Name" required/>
               </div>
               <div class="form_group ">
                  <label for="phone">Phone Number*</label>
                  <div class="phone_box">
                     <select id="country_code" name="country_code" class="flag_select">
                        <option value="+965" class="flag_option">
                           <img src="images/flag.svg" alt="US Flag" width="20" /> +965
                        </option>
                        <option value="+91" class="flag_option">
                           <img src="images/flag.svg" alt="India Flag" width="20" /> +91
                        </option>
                     </select>
                     <input type="tel" id="phone" name="phone" placeholder="Enter phone number" maxlength="14" minlength="14" required/>
                  </div>
               </div>
               <div class="form_group">
                   <label for="email">Email*</label>
                   <input type="email" id="email" name="email" placeholder="Enter Email" required/>
               </div>
               <div class="form_group">
                  <label for="password">Password*</label>
                  <div class="password_box">
                     <input type="password" id="password" name="password" class="password_input" placeholder="Password" required/>
                     <span class="show_password" ><img src="images/hide-icon.svg" class="hide"/> <img src="images/show-icon.svg" class="show"/> </span>
                     {/* <!-- <span class="hide_password" ></span> --> */}
                  </div>
               </div>
               <div class="form_group birth_date">
                   <label for="dob">Date of Birth*</label>
                   <input type="date" id="dob" name="dob" placeholder="Select date of birth" required/>
               </div>
               <div class="form_group gender gender_select">
                   <label>Gender*</label>
                   <div class="gender_options">
                       <label>
                           <input type="radio" name="gender" value="female" required/>
                           <span class="gender_cat">Female
                              <img src="images/female-icon.svg" alt="female"/>
                           </span>
                       </label>
                       <label>
                           <input type="radio" name="gender" value="male" required/>
                           <span class="gender_cat">Male
                              <img src="images/male-icon.svg" alt="Male"/>
                           </span>
                       </label>
                   </div>
               </div>
               <button type="submit" class="login_button register_btn">Register Now</button>
               <div class="create_account">
                     <p>Don't have an account? <a href="javascript:void(0);">Create New account</a></p>
               </div>
            </form>
         </div>
      </div>
   </section>
    <Footer/>
   </>
  )
}

export default Registration
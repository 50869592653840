import React from 'react'
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'

function EditProfile() {
  return (
    <>
        <Header/>
        <section class="banners" style={{backgroundImage: `url(${'../../images/banners_bg.webp'})`}}>
      <div class="container">
         <div class="banner_head">
            <h1>Edit profile</h1>
            <p>An enim nullam tempor sapien gravida donec enim ipsum <br/> porta justo  congue purus pretium ligula </p>
         </div>
         <div class="bredcrub">
            <a href="index.html" target="_self"> Home </a><span> <img src="images/arrow.png" alt="arrow"/></span> 
            <p>Edit profile </p>
         </div>
      </div>
   </section>
   <section class="profile_edit ptb100">
      <div class="container">
         <div class="profile_box">
            <form action="#" method="post" class="edit_profile" id="edit_profile">
               <div class="avatar_box edit_avatar">
                  <div class="avatar_inner edit_inner">
                      <img src="images/editer_person.webp" alt="Avatar" id="avatarPreview" class="avatar_img edit_img" />
                      <label for="avatarUpload" class="upload_btn edit_btn">
                          <img src="images/camera-icon.webp" alt="Camera Icon" width="20" height="20"/>
                      </label>
                      <input type="file" id="avatarUpload" name="avatarUpload" accept="image/*" onchange="previewAvatar(event)"/>
                  </div>
               </div>
               <div class="edit_grid edit_box">
                  <div class="form_edit">
                      <label for="fullName">Full Name*</label>
                      <input type="text" id="fullName" name="fullName" placeholder="Enter Full Name" required/>
                  </div>
                  <div class=" form_edit">
                         <label for="phone">Phone Number*</label>
                         <div class="phone_box">
                            <select id="country_code" name="country_code">
                                <option value="+965">+965</option>
                                <option value="+91">+91</option>
                            </select>
                            <input type="tel" id="phone" name="phone" maxlength="14" minlength="14" placeholder="Enter phone number" required/>
                         </div>
                  </div>
               </div>
               <div class="edit_grid">
                  <div class=" form_edit">
                      <label for="email">Email*</label>
                      <input type="email" id="email" name="email" placeholder="Enter Email" required/>
                  </div>
                  <div class=" form_edit edit_date birth_date">
                     <label for="dob">Date of Birth*</label>
                     <input type="date" id="dob" name="dob" placeholder="Select date of birth" required/>
                  </div>
               </div>
               
               <div class="selection_box">               
                  <label>Gender*</label>
                  <div class="gender_selection">
                     <div class="genders_grid">
                        <input type="radio" id="female" name="gender" value="female" required/>
                        <label for="female" class="gender_option">
                            <span>Female</span>
                            <img src="images/female-icon.svg" alt="Female Icon"/>
                        </label>
                     </div>
                     <div class="genders_grid">
                        <input type="radio" id="male" name="gender" value="male" required/>
                        <label for="male" class="gender_option">
                           <span>Male</span>
                              <img src="images/male-icon.svg" alt="Male Icon"/>
                        </label>
                     </div>  
                  </div>
               </div>
               <button type="submit" class="edit_sub">Edit profile</button>
            </form>
         </div>
      </div>
   </section>
        <Footer/>
    </>
  )
}

export default EditProfile
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/style.css';
import '../../css/reset.css';
import '../../css/responsive.css';
import '../../css/glightbox.css';

function Header() {
  const [isHeaderShown, setIsHeaderShown] = useState(false);

  const handleToggle = () => {
    setIsHeaderShown(!isHeaderShown);
  };

  return (
    <>
      <header className={`header ${isHeaderShown ? 'show' : ''}`}>
        <div className="container">
          <div className="header_navbar">
            <Link to="/" className="logo">
              <img src="/images/company_lovo.webp" alt="company logo rehla" />
            </Link>
            <ul className="header_menu">
              <li><Link to="/" className="active">Home</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/prebooking-trips">Prebooking Trips</Link></li>
              <li><Link to="/tourism-tours">Tourism Tours</Link></li>
              <li><Link to="/news">News</Link></li>
              <li><Link to="/about-us">AboutUs</Link></li>
              <li><Link to="/contact-us">ContactUs</Link></li>
            </ul>
          </div>
          <div className="toggle_grid">
            <ul className="login_box">
              <li><Link to="/login" className="login">Login</Link></li>
              <li>
                <a href="#" className="language">
                  عربي <img src="/images/internet.svg" alt="language icon" />
                </a>
              </li>
            </ul>
            <div className="toggle_btn">
              <svg
                className={`ham hamRotate ham7 ${isHeaderShown ? 'active' : ''}`}
                viewBox="0 0 100 100"
                width="80"
                onClick={handleToggle}
              >
                <path
                  className="line top"
                  d="m 70,33 h -40 c 0,0 -6,1.368796 -6,8.5 0,7.131204 6,8.5013 6,8.5013 l 20,-0.0013"
                ></path>
                <path className="line middle" d="m 70,50 h -40"></path>
                <path
                  className="line bottom"
                  d="m 69.575405,67.073826 h -40 c -5.592752,0 -6.873604,-9.348582 1.371031,-9.348582 8.244634,0 19.053564,21.797129 19.053564,12.274756 l 0,-40"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;